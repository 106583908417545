<template>
  <div
    style="position: relative; height: calc(100vh - 50px); overflow-y: scroll"
  >
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />
    <div class="activity_create_back" @click="handleBack">
      <el-button type="text">
        <i class="el-icon-arrow-left"></i>返回酒店详情</el-button
      >
    </div>

    <div class="activity_create_title">编辑房型</div>

    <div>
      <el-table
        :data="tableData"
        :row-class-name="rowClassName"
        style="width: 100%"
        border
      >
        <el-table-column
          label="图片"
          align="center"
          prop="roomInfo.images"
          width="200"
        >
          <template slot-scope="{ row, $index }">
            <div v-if="!showEdit[$index]">
              <div
                v-if="typeof tableData[row.xh - 1].roomInfo.images == 'object'"
              >
                <el-image
                  style="width: 100px; height: 100px"
                  :src="tableData[row.xh - 1].roomInfo.images[0]"
                  :preview-src-list="tableData[row.xh - 1].roomInfo.images"
                >
                </el-image>
              </div>

              <div
                v-else-if="
                  typeof tableData[row.xh - 1].roomInfo.images == 'string'
                "
              >
                <el-image
                  style="width: 100px; height: 100px"
                  :src="tableData[row.xh - 1].roomInfo.images[0]"
                  :preview-src-list="[
                    tableData[row.xh - 1].roomInfo.images.split(','),
                  ]"
                >
                </el-image>
              </div>
            </div>
            <el-upload
              :action="UploadUrl()"
              ref="uploadimgTable"
              :limit="10"
              :auto-upload="false"
              :on-change="imageChangeTable"
              :show-file-list="true"
              :file-list="fileListTable"
              list-type="picture-card"
              :on-exceed="handleExceedTable"
              :before-remove="handleRemoveTable"
              :class="{ disabled: uploadDisabledTable }"
              v-if="showEdit[$index]"
            >
              <i class="el-icon-plus"></i>
            </el-upload> </template
        ></el-table-column>
        <el-table-column
          prop="goodsName"
          align="center"
          :required="true"
          label="名称"
          width="150"
        >
          <template slot-scope="{ row, $index }">
            <span v-if="!showEdit[$index]">{{ row.roomInfo.goodsName }}</span>
            <el-input
              v-model="tableData[row.xh - 1].roomInfo.goodsName"
              v-if="showEdit[$index]"
              placeholder="请输入名称"
            >
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="roomDesc"
          align="center"
          :required="true"
          label="房间描述"
          width="200"
        >
          <template slot-scope="{ row, $index }">
            <span v-if="!showEdit[$index]">{{ row.roomInfo.roomDesc }}</span>
            <el-input
              v-model="tableData[row.xh - 1].roomInfo.roomDesc"
              placeholder="请输入房间描述"
              v-if="showEdit[$index]"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="bedDesc"
          align="center"
          :required="true"
          label="床型描述"
          width="200"
        >
          <template slot-scope="{ row, $index }">
            <span v-if="!showEdit[$index]">{{ row.roomInfo.bedDesc }}</span>
            <el-input
              v-model="tableData[row.xh - 1].roomInfo.bedDesc"
              placeholder="请输入床型描述"
              v-if="showEdit[$index]"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="roomInfo.roomCapacity"
          align="center"
          :required="true"
          label="房间容量"
          width="160"
        >
          <template slot-scope="{ row, $index }">
            <span v-if="!showEdit[$index]">{{
              row.roomInfo.roomCapacity || 0
            }}</span>
            <el-input-number
              v-model="tableData[row.xh - 1].roomInfo.roomCapacity"
              v-if="showEdit[$index]"
            ></el-input-number>
          </template>
        </el-table-column>
        <el-table-column
          prop="roomInfo.cancelRule"
          align="center"
          label="退订政策"
          width="150"
        >
          <template slot-scope="{ row, $index }">
            <span v-if="!showEdit[$index]">{{
              row.roomInfo.cancelRule == 0
                ? "不可退订"
                : row.roomInfo.cancelRule == 1
                ? "提前1天退订"
                : row.roomInfo.cancelRule == 2
                ? "提前3天退订"
                : row.roomInfo.cancelRule == 3
                ? "提前7天退订"
                : row.roomInfo.cancelRule == 4
                ? "提前14天退订"
                : ""
            }}</span>
            <el-select
              v-model="tableData[row.xh - 1].roomInfo.cancelRule"
              v-if="showEdit[$index]"
              placeholder="请选择"
              value-key="value"
              @change="cancelRuleChange"
            >
              <el-option
                v-for="item in cancelRuleArray"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          prop="roomInfo.hasBreakfast"
          align="center"
          :required="true"
          label="是否含早餐"
          width="100"
        >
          <template slot-scope="{ row, $index }">
            <span v-if="!showEdit[$index]">{{
              row.roomInfo.hasBreakfast ? "包含" : "不包含"
            }}</span>
            <el-checkbox
              v-model="tableData[row.xh - 1].roomInfo.hasBreakfast"
              v-if="showEdit[$index]"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="roomInfo.baseStock"
          align="center"
          :required="true"
          label="默认库存"
          width="160"
        >
          <template slot-scope="{ row, $index }">
            <span v-if="!showEdit[$index]">{{
              row.roomInfo.baseStock || 0
            }}</span>
            <el-input-number
              v-model="tableData[row.xh - 1].roomInfo.baseStock"
              v-if="showEdit[$index]"
            ></el-input-number>
          </template>
        </el-table-column>
        <el-table-column
          prop="roomInfo.basePrice"
          align="center"
          :required="true"
          label="默认价格"
          width="280"
        >
          <template slot-scope="{ row, $index }">
            <span v-if="!showEdit[$index]">{{ row.roomInfo.basePrice }}</span>
            <hr />
            <el-input-number
              style="width: 200px"
              v-model="tableData[row.xh - 1].roomInfo.basePrice"
              v-if="showEdit[$index]"
            ></el-input-number>
            <div class="create_table">
              <div>
                <p>A级专属价</p>
                <p>
                  <span v-if="tableData[row.xh - 1].roomInfo.aDiscount > 1">
                    {{ tableData[row.xh - 1].roomInfo.aDiscount || 10 }}折
                  </span>
                  <span
                    v-else-if="tableData[row.xh - 1].roomInfo.aDiscount <= 1"
                  >
                    {{
                      (tableData[row.xh - 1].roomInfo.aDiscount * 10).toFixed(
                        1
                      ) || 10
                    }}折
                  </span>
                  <i
                    class="el-icon-edit"
                    style="cursor: pointer"
                    @click="handleEditPrice('a', row, $index)"
                    v-if="showEdit[$index]"
                  ></i>
                </p>
              </div>
              <div>
                <p>B级专属价</p>
                <p>
                  <span v-if="tableData[row.xh - 1].roomInfo.bDiscount > 1">
                    {{ tableData[row.xh - 1].roomInfo.bDiscount || 10 }}折
                  </span>
                  <span
                    v-else-if="tableData[row.xh - 1].roomInfo.bDiscount <= 1"
                  >
                    {{
                      (tableData[row.xh - 1].roomInfo.bDiscount * 10).toFixed(
                        1
                      ) || 10
                    }}折
                  </span>
                  <i
                    class="el-icon-edit"
                    style="cursor: pointer"
                    @click="handleEditPrice('b', row, $index)"
                    v-if="showEdit[$index]"
                  ></i>
                </p>
              </div>
              <div>
                <p>C级专属价</p>
                <p>
                  <span v-if="tableData[row.xh - 1].roomInfo.cDiscount > 1">
                    {{ tableData[row.xh - 1].roomInfo.cDiscount || 10 }}折
                  </span>
                  <span
                    v-else-if="tableData[row.xh - 1].roomInfo.cDiscount <= 1"
                  >
                    {{
                      (tableData[row.xh - 1].roomInfo.cDiscount * 10).toFixed(
                        1
                      ) || 10
                    }}折
                  </span>
                  <i
                    class="el-icon-edit"
                    style="cursor: pointer"
                    @click="handleEditPrice('c', row, $index)"
                    v-if="showEdit[$index]"
                  ></i>
                </p>
              </div>
              <div>
                <p>D级专属价</p>
                <p>
                  <span v-if="tableData[row.xh - 1].roomInfo.dDiscount > 1">
                    {{ tableData[row.xh - 1].roomInfo.dDiscount || 10 }}折
                  </span>
                  <span
                    v-else-if="tableData[row.xh - 1].roomInfo.dDiscount <= 1"
                  >
                    {{
                      (tableData[row.xh - 1].roomInfo.dDiscount * 10).toFixed(
                        1
                      ) || 10
                    }}折
                  </span>
                  <i
                    class="el-icon-edit"
                    style="cursor: pointer"
                    @click="handleEditPrice('d', row, $index)"
                    v-if="showEdit[$index]"
                  ></i>
                </p>
              </div>

              <el-dialog
                title="设置专属折扣"
                :append-to-body="true"
                :visible.sync="dialogFormVisible"
              >
                <el-form :model="exclusivePrice">
                  <p style="margin: 0 0 10px 30px; color: #f56c6c">
                    ps：输入8.5 则为八五折，输入9 则为九折
                  </p>
                  <el-form-item
                    label="A级专属折扣"
                    :label-width="formLabelWidth"
                    v-if="priceType == 1"
                  >
                    <el-input-number
                      style="width: 300px"
                      v-model="exclusivePrice.aDiscount"
                      :min="0"
                      :max="10"
                      autocomplete="off"
                    ></el-input-number>
                  </el-form-item>
                  <el-form-item
                    label="B级专属折扣"
                    :label-width="formLabelWidth"
                    v-else-if="priceType == 2"
                  >
                    <el-input-number
                      style="width: 300px"
                      v-model="exclusivePrice.bDiscount"
                      :min="0"
                      :max="10"
                      autocomplete="off"
                    ></el-input-number>
                  </el-form-item>
                  <el-form-item
                    label="C级专属折扣"
                    :label-width="formLabelWidth"
                    v-else-if="priceType == 3"
                  >
                    <el-input-number
                      style="width: 300px"
                      v-model="exclusivePrice.cDiscount"
                      :min="0"
                      :max="10"
                      autocomplete="off"
                    ></el-input-number>
                  </el-form-item>
                  <el-form-item
                    label="D级专属折扣"
                    :label-width="formLabelWidth"
                    v-else-if="priceType == 4"
                  >
                    <el-input-number
                      style="width: 300px"
                      v-model="exclusivePrice.dDiscount"
                      :min="0"
                      :max="10"
                      autocomplete="off"
                    ></el-input-number>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisible = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="exclusivepriceType"
                    >确 定</el-button
                  >
                </div>
              </el-dialog>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          :required="true"
          label="指定日期价格"
          width="130"
        >
          <template slot-scope="{ row, $index }">
            <el-button
              type="text"
              @click="handleSetDatePrice($index)"
              :disabled="!showEdit[$index]"
              >设置</el-button
            >
            <el-dialog
              title="指定日期价格"
              :visible.sync="dialogDatePriceVisible"
              :append-to-body="true"
            >
              <div>
                <el-table
                  :data="tableDataPrice"
                  :row-class-name="rowClassNamePrice"
                  style="width: 100%"
                  border
                >
                  <el-table-column
                    prop="mescode"
                    align="center"
                    :required="true"
                    label="日期"
                  >
                    <template slot-scope="{ row, $index }">
                      <div v-if="!showEditPrice[$index]">
                        <div v-if="typeof row.roomDate == 'string'">
                          {{ row.roomDate }}
                        </div>
                        <div v-else-if="typeof row.roomDate == 'object'">
                          <div v-for="(item, i) in roomDateSkuList" :key="i">
                            {{ item }}
                          </div>
                        </div>
                      </div>

                      <div>
                        <el-date-picker
                          type="dates"
                          v-model="tableDataPrice[row.xh - 1].roomDate"
                          value-format="yyyy-MM-dd"
                          placeholder="选择⼀个或多个⽇期"
                          :picker-options="pickerOptions"
                          v-if="showEditPrice[$index]"
                        ></el-date-picker>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="roomSkuList.price"
                    align="center"
                    :required="true"
                    label="价格"
                  >
                    <template slot-scope="{ row, $index }">
                      <span v-if="!showEditPrice[$index]">{{
                        row.basePrice
                      }}</span>
                      <el-input-number
                        v-model="tableDataPrice[row.xh - 1].basePrice"
                        v-if="showEditPrice[$index]"
                      ></el-input-number>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="roomSkuList.stock"
                    align="center"
                    :required="true"
                    label="库存"
                  >
                    <template slot-scope="{ row, $index }">
                      <span v-if="!showEditPrice[$index]">{{ row.stock }}</span>
                      <el-input-number
                        v-model="tableDataPrice[row.xh - 1].stock"
                        v-if="showEditPrice[$index]"
                      ></el-input-number>
                    </template>
                  </el-table-column>

                  <el-table-column
                    header-align="center"
                    align="center"
                    width="100"
                    label="操作"
                  >
                    <template slot-scope="{ row, $index }">
                      <el-button
                        v-if="!showEditPrice[$index]"
                        @click="showUpdatePrice($index, row)"
                        type="text"
                        size="small"
                        >编辑</el-button
                      >
                      <el-button
                        v-if="!showEditPrice[$index]"
                        type="text"
                        size="mini"
                        style="color: #f56c6c"
                        @click="handleDeleteBtnPrice($index, row)"
                        >删除</el-button
                      >
                      <el-button
                        v-if="showEditPrice[$index]"
                        @click="submitKeepPrice($index, row)"
                        type="text"
                        size="small"
                        style="color: #85ce61"
                        >确定</el-button
                      >
                      <el-button
                        v-if="showEditPrice[$index]"
                        @click="cancelUpdatePrice($index)"
                        type="text"
                        size="small"
                        style="color: red"
                        >取消</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>

                <el-button
                  type="success"
                  icon="el-icon-plus"
                  size="mini"
                  @click="handleAddBtnPrice"
                  >添加自选日期</el-button
                >
              </div>

              <div style="display: flex; align-items: center; margin-top: 10px">
                <p style="width: 120px">设置每周六价格</p>
                <el-input-number
                  style="width: 30%"
                  v-model="everySatPrice"
                  placeholder="请输入每周六价格"
                  :min="0"
                ></el-input-number>
              </div>
              <div style="display: flex; align-items: center; margin-top: 10px">
                <p style="width: 120px">设置每周日价格</p>
                <el-input-number
                  style="width: 30%"
                  v-model="everySunPrice"
                  placeholder="请输入每周六价格"
                  :min="0"
                ></el-input-number>
              </div>

              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogDatePriceVisible = false"
                  >取 消</el-button
                >
                <el-button
                  type="primary"
                  @click="handleAddBtnPriceSubmit($index)"
                  >确 定</el-button
                >
              </span>
            </el-dialog>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          fixed="right"
          width="100"
          label="操作"
        >
          <template slot-scope="{ row, $index }">
            <div v-if="row.roomInfo.isVisible">
              <el-button
                v-if="!showEdit[$index]"
                @click="showUpdate($index, row)"
                type="text"
                size="small"
                >编辑</el-button
              >
              <el-button
                v-if="!showEdit[$index]"
                type="text"
                size="mini"
                style="color: #f56c6c"
                @click="handleDeleteBtn($index, row)"
                >删除</el-button
              >
              <el-button
                v-if="showEdit[$index]"
                @click="submitKeep($index, row)"
                type="text"
                size="small"
                style="color: #85ce61"
                >确定</el-button
              >
              <el-button
                v-if="showEdit[$index]"
                @click="cancelUpdate($index)"
                type="text"
                size="small"
                style="color: red"
                >取消</el-button
              >
            </div>
            <div v-else-if="!row.roomInfo.isVisible" style="font-size: 12px">
              已下架<br />不可编辑
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-button
        type="success"
        icon="el-icon-plus"
        size="mini"
        @click="handleAddBtn"
        >添加</el-button
      >
    </div>

    <div class="edit_house_footer">
      <el-button type="primary" @click="handleSubmit">提 交</el-button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Breadcrumb from "@/components/BreadCrumb";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { hideLoading, showLoading } from "@/util/loading";

import {
  format_time_date_noTime,
  removeTrailingZeros,
} from "@/util/funBusiness";

export default {
  components: {
    Breadcrumb,
  },

  data() {
    return {
      BreadcrumbCon: [
        {
          name: "行世界",
        },
        {
          name: "酒店发布管理",
        },
        {
          name: "编辑房型",
        },
      ], // 面包屑数据

      tableData: [],
      cancelRuleArray: [
        {
          value: 0,
          label: "不可退订",
        },
        {
          value: 1,
          label: "提前1天退订",
        },
        {
          value: 2,
          label: "提前3天退订",
        },
        {
          value: 3,
          label: "提前7天退订",
        },
        {
          value: 4,
          label: "提前14天退订",
        },
      ],

      showEdit: [], //控制显示及隐藏
      getCancelRule: [],

      exclusivePrice: {
        aDiscount: 1,
        bDiscount: 1,
        cDiscount: 1,
        dDiscount: 1,
      },

      priceType: 1,

      dialogFormVisible: false,
      formLabelWidth: "120px",
      dialogDatePriceVisible: false,
      roomDateSkuList: [],
      deleteList: [],
      editPriceIndex: "",

      // 指定日期价格
      roomDate: "",
      everySatPrice: "",
      everySunPrice: "",
      tableDataPrice: [],
      showEditPrice: [], //控制显示及隐藏
      pickerOptions: {},
      fileListTable: [],
      fileListImgTable: [],
      uploadDisabledTable: false,
      tableIndex: 0,
    };
  },

  mounted() {
    showLoading();
    const opt = {
      url: reqUrl.worldHotelGetRoomListByHotelId,
      method: "POST",
      params: JSON.stringify({
        hotelId: this.$route.query.hotelId,
      }),
      resFunc: (res) => {
        console.log(res.data, 1);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        } else if (res.data.code == 0) {
          this.tableData = res.data.data;

          res.data.data.map((item) => {
            this.tableDataPrice = item.roomSkuList;
            item.roomSkuList.map(
              (roomItem) =>
                (roomItem.roomDate = format_time_date_noTime(roomItem.roomDate))
            );

            item.roomInfo.aDiscount = (item.roomInfo.aDiscount * 10).toFixed(1);
            item.roomInfo.bDiscount = (item.roomInfo.bDiscount * 10).toFixed(1);
            item.roomInfo.cDiscount = (item.roomInfo.cDiscount * 10).toFixed(1);
            item.roomInfo.dDiscount = (item.roomInfo.dDiscount * 10).toFixed(1);

            item.roomInfo.images = item.roomInfo.images.split(",");
          });

          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
      },
    };
    request(opt);
  },

  methods: {
    handleBack() {
      this.$router.push({
        path: "/worldHotelDetail",
        query: {
          hotelId: this.$route.query.hotelId,
        },
      });
    },

    UploadUrl() {
      return reqUrl.imageUpload;
    },

    //表格的新增
    rowClassName({ row, rowIndex }) {
      row.xh = rowIndex + 1;
    },

    //点击新增更多
    handleAddBtn() {
      this.getCancelRule = "";
      let obj = {};
      obj = {
        roomInfo: {
          images: [],
          goodsName: "",
          roomDesc: "",
          bedDesc: "",
          roomCapacity: 0,
          baseStock: 0,
          cancelRule: "",
          hasBreakfast: true,
          basePrice: 0,
          aDiscount: 10,
          bDiscount: 10,
          cDiscount: 10,
          dDiscount: 10,
          everySatPrice: "",
          everySunPrice: "",
          isVisible: true,
        },

        roomSkuList: [],
      };

      this.tableData.push(obj);
      this.uploadDisabledTable = false;
    },

    //删除
    handleDeleteBtn(index, val) {
      this.$confirm("请确认是否要删除该项", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.tableData.forEach((v, i) => {
          let delList = [];
          delList.push(v.roomInfo.id);
          this.deleteList = delList;
          if (val.xh === v.xh) {
            this.tableData.splice(i, 1);
          }
        });
      });
    },

    //点击修改
    showUpdate(index, row) {
      this.showEdit[index] = true;
      this.$set(this.showEdit, index, true); //这里要用$set方法，否则页面状态不更新
      this.tableData[index].roomSkuList = row.roomSkuList;
      console.log(row.roomSkuList, "row.roomSkuList xiugai");

      if (row.roomInfo.images.length > 0) {
        let arr = [];
        row.roomInfo.images.map((item) => {
          arr.push({ url: item });
        });
        this.fileListTable = arr;
        this.fileListImgTable = row.roomInfo.images;
      } else {
        this.fileListTable = row.roomInfo.images;
      }

      if (this.fileListTable.length >= 10) {
        this.uploadDisabledTable = true;
      }

      if (row.roomSkuList.length < 1) {
        this.tableDataPrice = row.roomSkuList;
      }
    },

    //取消修改
    cancelUpdate(index) {
      this.$set(this.showEdit, index, false);
    },

    //提交修改
    submitKeep(index, row) {
      //发送请求，隐藏输入框
      this.$message({
        type: "success",
        message: "已缓存，记得点击保存提交修改喔！",
        duration: 888,
        onClose: () => {
          this.$set(this.showEdit, index, false); //vue添加属性的方法
        },
      });

      setTimeout(() => {
        row.roomInfo.images = this.fileListImgTable;

        this.tableDataPrice = this.tableData[index].roomSkuList;

        this.tableIndex = index;

        console.log(row.roomSkuList, "queding row.roomSkuList");
        console.log(this.tableDataPrice, "确定this.tableDataPrice");
      }, 1000);
    },

    // 退订政策下拉框
    cancelRuleChange(e) {
      this.getCancelRule = e;
    },

    // 设置专属价格
    handleEditPrice(type, row, index) {
      this.dialogFormVisible = true;
      this.editPriceIndex = index;
      this.exclusivePrice.aDiscount = row.roomInfo.aDiscount;
      this.exclusivePrice.bDiscount = row.roomInfo.bDiscount;
      this.exclusivePrice.cDiscount = row.roomInfo.cDiscount;
      this.exclusivePrice.dDiscount = row.roomInfo.dDiscount;
      if (type == "a") {
        this.priceType = 1;
        this.exclusivePrice.aDiscount = row.roomInfo.aDiscount;
      } else if (type == "b") {
        this.priceType = 2;
        this.exclusivePrice.bDiscount = row.roomInfo.bDiscount;
      } else if (type == "c") {
        this.priceType = 3;
        this.exclusivePrice.cDiscount = row.roomInfo.cDiscount;
      } else if (type == "d") {
        this.priceType = 4;
        this.exclusivePrice.dDiscount = row.roomInfo.dDiscount;
      }
    },

    exclusivepriceType() {
      this.tableData[this.editPriceIndex].roomInfo.aDiscount =
        this.exclusivePrice.aDiscount;
      this.tableData[this.editPriceIndex].roomInfo.bDiscount =
        this.exclusivePrice.bDiscount;
      this.tableData[this.editPriceIndex].roomInfo.cDiscount =
        this.exclusivePrice.cDiscount;
      this.tableData[this.editPriceIndex].roomInfo.dDiscount =
        this.exclusivePrice.dDiscount;
      this.dialogFormVisible = false;
    },

    // 指定日期价格
    handleSetDatePrice(index) {
      this.dialogDatePriceVisible = true;

      this.tableData.forEach((item, i) => {
        if (i === index) {
          this.tableDataPrice = item.roomSkuList;
          item.roomSkuList.map((roomItem) => {
            if (typeof roomItem.roomDate !== "object") {
              roomItem.roomDate = format_time_date_noTime(roomItem.roomDate);
            }
          });
        } else if (index >= (this.tableData || []).length) {
          this.tableDataPrice = [];
        }
      });

      this.everySatPrice = this.tableData[index].roomInfo.everySatPrice;
      this.everySunPrice = this.tableData[index].roomInfo.everySunPrice;
    },
    rowClassNamePrice({ row, rowIndex }) {
      row.xh = rowIndex + 1;
    },
    handleAddBtnPrice() {
      let obj = {};
      obj = {
        roomDate: "",
        basePrice: 0,
        stock: 0,
      };
      this.tableDataPrice.push(obj);
    },
    showUpdatePrice(index, row) {
      this.showEditPrice[index] = true;
      this.$set(this.showEditPrice, index, true); //这里要用$set方法，否则页面状态不更新
    },
    cancelUpdatePrice(index) {
      this.$set(this.showEditPrice, index, false);
    },
    submitKeepPrice(index, row) {
      //发送请求，隐藏输入框
      this.$message({
        type: "success",
        message: "已缓存，记得点击保存提交修改喔！",
        duration: 888,
        onClose: () => {
          this.$set(this.showEditPrice, index, false); //vue添加属性的方法
        },
      });

      this.tableDataPrice.map((item) => {
        if (typeof item.roomDate == "object") {
          this.roomDateSkuList = item.roomDate;
        }
      });
    },
    handleDeleteBtnPrice(index, val) {
      this.$confirm("请确认是否要删除该项", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.tableDataPrice.forEach((v, i) => {
          if (val.xh === v.xh) {
            this.tableDataPrice.splice(i, 1);
          }
        });
      });
    },

    handleAddBtnPriceSubmit(index) {
      this.dialogDatePriceVisible = false;

      let arr = [];
      this.tableDataPrice.map((item, index) => {
        const { basePrice, roomDate, stock, ...item1 } = item;

        if (typeof item.roomDate !== "string") {
          item.roomDate.forEach((date) =>
            arr.push({
              roomDate: date,
              basePrice: item.basePrice.toString(),
              stock: item.stock,
              ...item1,
            })
          );
        } else {
          arr.push({
            roomDate: item.roomDate,
            basePrice: item.basePrice,
            stock: item.stock,
            ...item1,
          });
        }
      });

      this.tableDataPrice = arr;

      this.tableData[index].roomInfo.everySatPrice = this.everySatPrice;
      this.tableData[index].roomInfo.everySunPrice = this.everySunPrice;
    },
    //选择图片后做图片格式限制（手动上传图片时，before-upload钩子无效，使用此方法替代）
    imageChangeTable(file, fileList) {
      if (fileList.length >= 10) {
        this.uploadDisabledTable = true;
      }
      axios.get(file.url, { responseType: "blob" }).then((res) => {
        const formData = new FormData();
        formData.append("file", res.data);
        formData.append("userId", 1);
        formData.append("scene", 2);

        axios.defaults.headers.post["Authorization"] = localStorage.getItem(
          "resHeadersAuthorization"
        );
        axios({
          method: "POST",
          url: reqUrl.imageUpload,
          header: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        }).then((res) => {
          this.fileListImgTable.push(res.data.data.url);
          this.fileListTable.push({ url: res.data.data.url });
        });
      });

      const isImage =
        file.raw.type == "image/png" ||
        file.raw.type == "image/jpg" ||
        file.raw.type == "image/jpeg";
      const isLt5M = file.size < 1024 * 1024 * 10;
      if (!isImage) {
        this.$message.error("上传只能是png,jpg,jpeg格式!");
      }
      if (!isLt5M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }

      if (isImage && isLt5M) {
        this.uploadFile = file.raw || null;
      } else {
        fileList.splice(-1, 1);
      }
    },
    //超出限制个数钩子
    handleExceedTable(files) {
      this.$message.warning("当前限制选择10个文件，请删除图片后重新添加");
    },

    // 删除图片
    handleRemoveTable(file, fileList) {
      const index = fileList.findIndex((item) => {
        return item.uid === file.uid;
      });
      this.fileListImgTable.splice(index, 1);
      this.fileListTable.splice(index, 1);

      if (this.fileListImgTable.length < 1) {
        this.uploadDisabledTable = false;
      }
    },

    handleSubmit() {
      this.tableData.forEach((item) => {
        item.roomInfo.aDiscount =
          parseInt((item.roomInfo.aDiscount / 10) * 100) / 100;
        item.roomInfo.bDiscount =
          parseInt((item.roomInfo.bDiscount / 10) * 100) / 100;
        item.roomInfo.cDiscount =
          parseInt((item.roomInfo.cDiscount / 10) * 100) / 100;
        item.roomInfo.dDiscount =
          parseInt((item.roomInfo.dDiscount / 10) * 100) / 100;
        item.roomInfo.images = item.roomInfo.images.join(",");
      });
      console.log(this.tableData);

      showLoading();
      const opt = {
        url: reqUrl.worldHotelEditRoom,
        method: "POST",
        params: JSON.stringify({
          hotelId: this.$route.query.hotelId,
          deleteList: this.deleteList,
          editList: this.tableData,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            hideLoading();
            this.$router.push({
              path: "/worldHotelDetail",
              query: {
                hotelId: this.$route.query.hotelId,
              },
            });
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
        },
      };
      request(opt);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../index.scss";

.edit_house_footer {
  display: flex;
  justify-content: center;

  .el-button {
    width: 300px;
    border-radius: 20px;
  }
}

::v-deep .el-input-number {
  width: 130px;

  .el-input-number__decrease,
  .el-input-number__increase {
    width: 32px;
  }
}
</style>
